import {backendApi} from "../backend-api-sr"

export const data_jemaat = (() =>{
    const test = (() => {
        return ""
    })

    const fetchDataJemaat = ( async (subPath) => {
        var url = '/api/cek_data_jemaat'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const storeDataJemaat = ( async (subPath, reqBody) => {
        var url = '/api/manage_jemaat/input_jemaat'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const updateDataJemaat = ( async (subPath, reqBody) => {
        var url = '/api/manage_jemaat/update_jemaat'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const updateMenuHeaderStatus = ( async (subPath, reqBody) => {
        var url = '/api/manage_jemaat/updateMenuHeaderStatus'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const cekNamaJemaat = ( async (subPath) => {
        var url = '/api/cek_nama_jemaat'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const show_nama_jemaat = ( async (subPath, reqBody) => {
        var url = '/api/show_nama_jemaat'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {fetchDataJemaat, storeDataJemaat, updateDataJemaat,updateMenuHeaderStatus,cekNamaJemaat,show_nama_jemaat, test};

})()